import React from 'react';
import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import routes from './routes.tsx';
import { BlogProvider } from './contexts/BlogContext';

SuperTokens.init({
    appInfo: {
        appName: "numerology",
        apiDomain: "http://localhost:8000",
        websiteDomain: "http://localhost:3000",
        apiBasePath: "/auth",
        websiteBasePath: "/auth"
    },
    recipeList: [
        Passwordless.init({
            contactMethod: "EMAIL",
            signInUpFeature: {
                defaultToSignUp: true
            }
        }),
        Session.init()
    ]
});

function AppRoutes() {
    const element = useRoutes(routes);
    return element;
}

function App() {
    return (
        <SuperTokensWrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<AppRoutes />} />
                </Routes>
            </BrowserRouter>
        </SuperTokensWrapper>
    );
}

export default App;
import React from 'react';
import FAQ from './FAQ';
import ReportGenerator from './ReportGenerator';
import { Link } from 'react-router-dom';

function HomeContent() {
    return (
        <div className="max-w-6xl mx-auto px-4 py-8 ">
            <div className="text-center max-w-4xl mx-auto mt-[4rem]">
                <h1 className="text-3xl sm:text-4xl font-extrabold text-[#1A243B] mb-6 leading-tight">
                    Welcome to{' '}
                    <span className="bg-gradient-to-r from-[#3A3A8C] to-[#5D5DA8] text-transparent bg-clip-text">
                        NumerologyUnlocked
                    </span>
                </h1>
                <p className="text-xl text-gray-600 leading-relaxed mx-auto max-w-2xl">
                    Discover the mystical world of numbers and unlock your true potential. 
                </p>
                <p className="text-xl text-gray-600 leading-relaxed mx-auto max-w-2xl">
                    Our advanced numerology insights reveal the hidden patterns that shape your destiny.
                </p>
            </div>
            <ReportGenerator />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                <div className="bg-gradient-to-br p-8 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300">
                    <div className="flex items-center justify-between mb-3">
                        <h2 className="text-2xl font-bold text-[#3A3A8C]">Life Path Analysis</h2>
                        <div className="text-[#3A3A8C]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                            </svg>
                        </div>
                    </div>
                    <p className="text-[#3A3A8C]">Uncover your life's purpose through the ancient wisdom of numbers.</p>
                </div>

                <div className="bg-gradient-to-br p-8 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300">
                    <div className="flex items-center justify-between mb-3">
                        <h2 className="text-2xl font-bold text-[#3A3A8C]">Name Numerology</h2>
                        <div className="text-[#3A3A8C]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                            </svg>
                        </div>
                    </div>
                    <p className="text-[#3A3A8C]">Discover how your name influences your destiny and personality.</p>
                </div>

                <div className="bg-gradient-to-br p-8 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300">
                    <div className="flex items-center justify-between mb-3">
                        <h2 className="text-2xl font-bold text-[#3A3A8C]">Daily Numbers</h2>
                        <div className="text-[#3A3A8C]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                            </svg>
                        </div>
                    </div>
                    <p className="text-[#3A3A8C]">Get personalized daily insights and guidance for your journey.</p>
                </div>
            </div>

            <div className="mt-[3rem] mb-[3rem]">
                <FAQ />
            </div>
        </div>
    );
}

export default HomeContent; 
class ConfigService {
    static getApiUrl() {
        return process.env.REACT_APP_API_URL || 'http://localhost';
    }

    static getApiPort() {
        return process.env.REACT_APP_API_PORT || '8000';
    }

    static getEnvironment() {
        return process.env.REACT_APP_ENV || 'development';
    }

    static getStripePublishableKey() {
        return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_live_51Q3jAJGzPblntYEWST9T63Qq4XODdBZ39Ra59SVTXuItL7105bBYloIMmWUGTTmesoUejcPWpuiM2eJEuXE101pO00uKnt5nm3';
    }

    static getFullApiUrl() {
        return `${this.getApiUrl()}:${this.getApiPort()}`;
    }
}

export default ConfigService;

import { useState, useContext } from 'react';
import Modal from './Modal';
import { AuthContext } from '../contexts/AuthContext';
import { useOutletContext, useNavigate } from 'react-router-dom';

const NumerologyAnalysis = () => {
  const navigate = useNavigate();
  const { tokens, onTokensUpdate } = useOutletContext();
  // const { purchaseTokens } = useContext();
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [question, setQuestion] = useState('');
  const [isMorning, setIsMorning] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setError(null);
    setResponse(null);
    setIsLoading(true);

    try {
      const response = await fetch('http://localhost:8000/ask-cayo-orange/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          date_of_birth: new Date(dateOfBirth).toISOString(),
          question: question,
          is_morning: isMorning
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to get analysis');
      }

      const data = await response.json();
      setResponse(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecharge = () => {
    // purchaseTokens(() => {
    //   setShowTokenModal(false);
    //   onTokensUpdate();
    // });
  };

  const handleBack = () => {
    navigate('/analysis');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto" style={{ marginTop: '4rem' }}>
        <button
          onClick={handleBack}
          className="mb-6 flex items-center text-indigo-600 hover:text-indigo-800 transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5 mr-2" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path 
              fillRule="evenodd" 
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
              clipRule="evenodd" 
            />
          </svg>
          Back to Home
        </button>

        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight mb-4">
            Kabbalistic Numerology
          </h1>
          <p className="text-lg text-gray-600">
            Discover your personal numerological insights through ancient wisdom
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8 backdrop-blur-lg bg-opacity-90">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  placeholder="Enter your full name"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date of Birth
                </label>
                <input
                  type="date"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                  required
                />
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="isMorning"
                checked={isMorning}
                onChange={(e) => setIsMorning(e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="isMorning" className="ml-2 block text-sm text-gray-700">
                Born in the Morning
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Your Question
              </label>
              <textarea
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                rows="4"
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                placeholder="What would you like to know about your numerological path?"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center items-center py-3 px-4 rounded-lg text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all disabled:opacity-50"
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Analyzing...
                </>
              ) : (
                'Reveal Your Numerological Insights'
              )}
            </button>
          </form>

          {showTokenModal && (
            <Modal onClose={() => setShowTokenModal(false)}>
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-4">Insufficient Tokens</h3>
                <p className="mb-4">
                  You need at least 2000 tokens to perform a numerology analysis. 
                  Would you like to recharge your tokens?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setShowTokenModal(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleRecharge}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                  >
                    Recharge Tokens
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {error && (
            <div className="mt-6 p-4 rounded-lg bg-red-50 text-red-700">
              {error}
            </div>
          )}

          {response && (
            <div className="mt-6 p-6 rounded-lg bg-indigo-50 border border-indigo-100">
              <h3 className="text-lg font-semibold text-indigo-900 mb-3">
                Your Numerological Analysis
              </h3>
              <p className="text-indigo-800 whitespace-pre-wrap">
                {Object.values(response)[0]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumerologyAnalysis;

import ConfigService from './ConfigService';

const configService = new ConfigService();
class TokensService {
    static async fetchTokens() {
        try {
            const response = await fetch(`${configService.getFullApiUrl()}/get-tokens/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tokens');
            }

            const data = await response.json();
            return data.tokens;
        } catch (error) {
            console.error('Error fetching tokens:', error);
            throw error;
        }
    }
}

export default TokensService;

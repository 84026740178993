import React, { useState } from 'react';
import Passwordless from "supertokens-auth-react/recipe/passwordless";

function AuthDialog({ onClose, onSuccess }) {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSendCode = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        
        try {
            const response = await Passwordless.createCode({
                email,
            });
            if (response.status === "OK") {
                setShowCodeInput(true);
            } else {
                setError(`Error: ${response.status}`);
            }
        } catch (err) {
            console.error('Send code error:', err);
            setError(err.message || 'Failed to send code. Please try again.');
        }
        
        setLoading(false);
    };

    const handleValidateCode = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await Passwordless.consumeCode({
                userInputCode: code,
            });
            
            if (response.status === "OK") {
                if (onSuccess) {
                    await onSuccess();
                }
                onClose();
            } else {
                setError('Invalid code. Please try again.');
            }
        } catch (err) {
            setError('Failed to validate code. Please try again.');
        }

        setLoading(false);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                <h2 className="text-2xl font-bold mb-6 text-center">Sign In</h2>
                
                {error && (
                    <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
                        {error}
                    </div>
                )}

                {!showCodeInput ? (
                    // Email Input Form
                    <form onSubmit={handleSendCode}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 mb-2">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="flex space-x-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex-1 bg-blue-500 text-white py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
                            >
                                {loading ? 'Sending...' : 'Send Code'}
                            </button>
                            <button
                                type="button"
                                onClick={onClose}
                                className="flex-1 bg-gray-200 text-gray-700 py-2 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    // OTP Code Input Form
                    <form onSubmit={handleValidateCode}>
                        <div className="mb-4">
                            <label htmlFor="code" className="block text-gray-700 mb-2">
                                Enter Code
                            </label>
                            <input
                                type="text"
                                id="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="flex space-x-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex-1 bg-blue-500 text-white py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
                            >
                                {loading ? 'Validating...' : 'Validate'}
                            </button>
                            <button
                                type="button"
                                onClick={onClose}
                                className="flex-1 bg-gray-200 text-gray-700 py-2 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                        <button
                            type="button"
                            onClick={() => setShowCodeInput(false)}
                            className="w-full mt-4 text-gray-600 hover:text-gray-800"
                        >
                            Back to Email
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default AuthDialog; 
import React from 'react';
import type { RouteObject } from 'react-router-dom';
import HomePage from './pages/HomePage';
import HomeContent from './components/HomeContent';
import NumerologyAnalysis from './components/NumerologyAnalysis';
import BlogPosts from './components/BlogPosts';
import BlogPost from './components/BlogPost';
import ContactUsForm from './components/ContactUsForm';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import { BlogProvider } from './contexts/BlogContext';
import ReportGenerator from './components/ReportGenerator';
import ServiceSelector from './components/ServiceSelector';

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <BlogProvider>
        <HomePage/>
      </BlogProvider>
    ),
    children: [
      {
        path: '',
        element: <HomeContent />,
      },
      {
        path: 'analysis',
        element: <ServiceSelector />,
      },
      {
        path: 'blog',
        element: (
          <BlogProvider>
            <BlogPosts />
          </BlogProvider>
        )
      },
      {
        path: 'blog/:id',
        element: (
          <BlogProvider>
            <BlogPost />
          </BlogProvider>
        )
      },
      {
        path: 'contact',
        element: <ContactUsForm />,
      },
      {
        path: 'terms',
        element: <TermsOfUse />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'simple-report',
        element: <ReportGenerator reportType="simple" />,
      },
      {
        path: 'comprehensive-report',
        element: <ReportGenerator reportType="comprehensive" />,
      },
      {
        path: 'ask-question',
        element: <NumerologyAnalysis />,
      },
    ],
  },
];

export default routes;
  
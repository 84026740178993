import React from 'react';
import HomeContent from '../components/HomeContent';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import AuthDialog from '../components/AuthDialog';
import TokensService from '../services/TokensService';
import ConfigService from '../services/ConfigService';
import Navbar from '../components/Navbar';
import TokenPurchaseModal from '../components/TokenPurchaseModal';
import Footer from '../components/Footer';

function HomePage() {
    const session = useSessionContext();
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [tokens, setTokens] = useState(0);
    const [showTokenModal, setShowTokenModal] = useState(false);

    const fetchTokens = async () => {
        const updatedTokens = await TokensService.fetchTokens();
        setTokens(updatedTokens);
    };

    useEffect(() => {
        fetchTokens();
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
            <Navbar 
                tokens={tokens} 
                setShowTokenModal={setShowTokenModal} 
                setShowAuthDialog={setShowAuthDialog} 
            />

            {/* Main Content */}
            <Outlet context={{ tokens, onTokensUpdate: fetchTokens }} />

            {/* Auth Dialog */}
            {showAuthDialog && (
                <AuthDialog onClose={() => setShowAuthDialog(false)} />
            )}

            {/* Token Purchase Modal */}
            {showTokenModal && (
                <TokenPurchaseModal
                    isOpen={showTokenModal}
                    onClose={() => setShowTokenModal(false)}
                    onSuccess={() => {
                        fetchTokens();
                        setShowTokenModal(false);
                    }}
                />
            )}

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default HomePage; 
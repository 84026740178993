import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="max-w-6xl mx-auto px-4 py-8 mt-[4rem]">
            <h1 className="text-4xl font-bold text-[#3A3A8C] mb-6">Privacy Policy for NumerologyUnlocked</h1>
            <div className="prose prose-lg max-w-none">
                <p className="mb-4">Last updated: December 13, 2024</p>
                
                {/* Introduction Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">1. Introduction and Regulatory Compliance</h2>
                    <p>Welcome to NumerologyUnlocked (the "Website", "Service", "we", "us", or "our"). We are committed to protecting your personal information and complying with the following data protection regulations:</p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>General Data Protection Regulation (GDPR)</li>
                        <li>California Consumer Privacy Act (CCPA)</li>
                        <li>California Privacy Rights Act (CPRA)</li>
                        <li>California Online Privacy Protection Act (CalOPPA)</li>
                    </ul>
                </section>

                {/* Information Collection Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">2. Information We Collect</h2>
                    
                    <h3 className="text-xl font-semibold mb-2">2.1 Personal Information Categories (CCPA/CPRA Compliance)</h3>
                    <p>We may collect the following categories of personal information:</p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Identifiers (name, email address, IP address)</li>
                        <li>Personal characteristics (age, gender)</li>
                        <li>Commercial information (transaction history, services purchased)</li>
                        <li>Internet or electronic network activity information</li>
                        <li>Geolocation data</li>
                        <li>Inferred information</li>
                    </ul>

                    <h3 className="text-xl font-semibold mb-2">2.2 Collection Methods</h3>
                    <p>We collect information through:</p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Direct user submissions</li>
                        <li>Automated tracking technologies</li>
                        <li>Payment processing systems</li>
                        <li>Google Analytics</li>
                    </ul>
                </section>

                {/* Continue with remaining sections following the same pattern */}
                {/* Data Usage Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">3. Use of Personal Information</h2>
                    <h3 className="text-xl font-semibold mb-2">CCPA/CPRA Disclosed Purposes</h3>
                    <p>We use your personal information for:</p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Providing numerology services</li>
                        <li>Processing transactions</li>
                        <li>Communicating with you</li>
                        <li>Improving website functionality</li>
                        <li>Personalizing user experience</li>
                        <li>Preventing fraudulent activities</li>
                        <li>Complying with legal obligations</li>
                    </ul>
                </section>

                {/* Contact Information Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">13. Contact for Privacy Requests</h2>
                    <div className="bg-gray-50 p-6 rounded-lg">
                        <h3 className="text-xl font-semibold mb-2">Official Contact Information</h3>
                        <div className="mb-4">
                            <h4 className="font-semibold">Electronic Contact Methods</h4>
                            <p>Primary Email: numerologyunlockedofc@gmail.com</p>
                            <p>Website Contact Form: http://www.numerology-unlocked.com/contact</p>
                            <p>Privacy Rights Portal: http://www.numerology-unlocked.com/privacy-rights</p>
                        </div>
                    </div>
                </section>

                {/* Final Notice */}
                <section className="mt-8 p-4 bg-gray-50 rounded-lg">
                    <p className="font-semibold">By using NumerologyUnlocked, you acknowledge understanding and accepting these privacy practices.</p>
                </section>
            </div>
        </div>
    );
}

export default PrivacyPolicy; 
import React from 'react';
import { ChartBarIcon, DocumentMagnifyingGlassIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const services = [
    {
        title: "Simple Report",
        description: "Get a quick overview of your numerology profile.",
        path: "/simple-report",
        icon: DocumentMagnifyingGlassIcon
    },
    {
        title: "Comprehensive Report",
        description: "Dive deep into your numerology insights with a detailed report.",
        path: "/comprehensive-report",
        icon: ChartBarIcon
    },
    {
        title: "Ask a Question",
        description: "Get personalized answers to your numerology questions.",
        path: "/ask-question",
        icon: QuestionMarkCircleIcon
    }
];

function ServiceSelector() {
    return (
        <div className="max-w-6xl mx-auto px-4 py-12 mt-[4rem]">
            <h2 className="text-3xl font-extrabold text-center mb-12">Choose Your Service</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {services.map((service, index) => {
                    const Icon = service.icon;
                    return (
                        <div
                            key={index}
                            className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 cursor-pointer overflow-hidden group"
                            onClick={() => window.location.href = service.path}
                        >
                            <div className="aspect-[3/4] flex flex-col">
                                <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-8 flex items-center justify-center flex-grow">
                                    <Icon className="w-24 h-24 text-white group-hover:scale-110 transition-transform duration-300" />
                                </div>
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                                    <p className="text-gray-600">{service.description}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ServiceSelector; 
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { signOut } from "supertokens-auth-react/recipe/session";

const Navbar = ({ tokens, setShowTokenModal, setShowAuthDialog }) => {
    const session = useSessionContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = async () => {
        await signOut();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLinkClick = () => {
        setIsMenuOpen(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <nav className="fixed top-0 left-0 right-0 w-full shadow-lg" style={{ backgroundColor: '#F8F8F1', zIndex: 1000 }}>
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex justify-between items-center h-16">
                    {/* Logo/Brand - Always visible */}
                    <div className="flex items-center">
                        <Link to="/" className="text-xl font-bold text-gray-800">
                            <img 
                                src="/numerology-unlocked-logo.png" 
                                alt="NumerologyUnlocked" 
                                className="h-10 w-[160px] object-contain" 
                            />
                        </Link>
                    </div>

                    {/* Hamburger Menu Button - Only visible on mobile */}
                    <button 
                        className="lg:hidden block p-2"
                        onClick={toggleMenu}
                    >
                        <svg 
                            className="w-6 h-6" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                            />
                        </svg>
                    </button>

                    {/* Navigation Links Container */}
                    <div className={`
                        flex items-center gap-6
                        lg:flex lg:relative
                        max-[800px]:fixed max-[800px]:top-16 max-[800px]:left-0 max-[800px]:right-0
                        max-[800px]:flex-col max-[800px]:items-start max-[800px]:bg-[#F8F8F1]
                        max-[800px]:p-4 max-[800px]:space-y-4
                        max-[800px]:shadow-lg
                        ${isMenuOpen ? 'max-[800px]:block' : 'max-[800px]:hidden'}
                    `}>
                        <div className="flex items-center gap-6 max-[800px]:flex-col max-[800px]:w-full">
                            <Link 
                                to="/" 
                                className="text-gray-600 hover:text-gray-900 whitespace-nowrap"
                                onClick={handleLinkClick}
                            >
                                Home
                            </Link>
                            <Link 
                                to="/analysis" 
                                className="text-gray-600 hover:text-gray-900 whitespace-nowrap"
                                onClick={handleLinkClick}
                            >
                                Analysis
                            </Link>
                            <Link 
                                to="/blog" 
                                className="text-gray-600 hover:text-gray-900 whitespace-nowrap"
                                onClick={handleLinkClick}
                            >
                                Blog
                            </Link>
                        </div>

                        <div className="flex items-center gap-6 max-[800px]:flex-col max-[800px]:w-full">
                            {/* Token Display */}
                            <div 
                                className="flex items-center bg-gray-50 px-3 py-1 rounded-full border border-gray-200 cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                                onClick={() => setShowTokenModal(true)}
                            >
                                <svg className="w-5 h-5 text-yellow-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.736 6.979C9.208 6.193 9.696 6 10 6c.304 0 .792.193 1.264.979a1 1 0 001.715-1.029C12.279 4.784 11.232 4 10 4s-2.279.784-2.979 1.95c-.285.475-.507 1.002-.67 1.55H6a1 1 0 000 2h.013a9.358 9.358 0 000 1H6a1 1 0 100 2h.351c.163.548.385 1.075.67 1.55C7.721 15.216 8.768 16 10 16s2.279-.784 2.979-1.95a1 1 0 10-1.715-1.029C10.792 13.807 10.304 14 10 14c-.304 0-.792-.193-1.264-.979a1 1 0 00-1.715 1.029C7.721 15.216 8.768 16 10 16s2.279-.784 2.979-1.95c.285-.475.507-1.002.67-1.55H14a1 1 0 100-2h-.013a9.358 9.358 0 000-1H14a1 1 0 000-2h-.351c-.163-.548-.385-1.075-.67-1.55C12.279 4.784 11.232 4 10 4s-2.279.784-2.979 1.95a1 1 0 101.715 1.029z" />
                                </svg>
                                <span className="font-medium text-gray-700">{tokens}</span>
                            </div>

                            {/* Auth Buttons */}
                            {!session.loading && (
                                session.doesSessionExist ? (
                                    <button
                                        onClick={handleLogout}
                                        className="whitespace-nowrap max-[800px]:w-full bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition duration-200"
                                    >
                                        Logout
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => setShowAuthDialog(true)}
                                        className="whitespace-nowrap max-[800px]:w-full bg-[#4285F4] hover:bg-blue-600 text-white px-6 py-2 rounded-lg font-medium text-sm transition duration-200"
                                    >
                                        Sign In
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar; 
import React from 'react';

function TermsOfUse() {
    return (
        <div className="max-w-6xl mx-auto px-4 py-8 mt-[4rem]">
            <h1 className="text-4xl font-bold text-[#3A3A8C] mb-6">Terms of Use for NumerologyUnlocked</h1>
            <div className="prose prose-lg max-w-none">
                <p className="mb-4">Last updated: December 13, 2024</p>

                {/* Acceptance Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">1. Acceptance of Terms</h2>
                    <p>By accessing or using NumerologyUnlocked (the "Website", "Service", "we", "us", or "our"), you agree to be bound by these Terms of Use. If you do not agree with these terms, please do not use our service.</p>
                </section>

                {/* Service Description */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">2. Service Description</h2>
                    <p>NumerologyUnlocked provides numerology readings, analyses, and related digital services through its website at http://www.numerology-unlocked.com/. These services are provided for personal insight and entertainment purposes only.</p>
                </section>

                {/* User Eligibility */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">3. User Eligibility</h2>
                    
                    <h3 className="text-xl font-semibold mb-2">3.1 Age Restrictions</h3>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Users must be at least 13 years old to use our service</li>
                        <li>Users between 13-18 require parental or guardian consent</li>
                        <li>Users under 13 are strictly prohibited from using the service</li>
                    </ul>

                    <h3 className="text-xl font-semibold mb-2">3.2 Account Requirements</h3>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Users must provide accurate and current information</li>
                        <li>One account per individual</li>
                        <li>Accounts are non-transferable</li>
                    </ul>
                </section>

                {/* Payment and Pricing */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">6. Payment and Pricing</h2>
                    
                    <div className="bg-gray-50 p-6 rounded-lg mb-4">
                        <h3 className="text-xl font-semibold mb-2">6.1 Service Fees</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Prices are listed in the current currency</li>
                            <li>All prices include applicable taxes</li>
                            <li>Prices are subject to change without prior notice</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2">6.2 Payment Methods</h3>
                        <p>We accept payments via:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Stripe</li>
                            <li>PayPal</li>
                        </ul>
                        <p>Payment is required to access certain services</p>

                        <h3 className="text-xl font-semibold mb-2">6.3 No Refund Policy</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>All sales are final</li>
                            <li>No refunds will be issued under any circumstances</li>
                            <li>Users are responsible for understanding the nature of the service before purchase</li>
                        </ul>
                    </div>
                </section>

                {/* Limitation of Liability */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">7. Limitation of Liability</h2>
                    
                    <div className="bg-gray-50 p-6 rounded-lg">
                        <h3 className="text-xl font-semibold mb-2">7.1 Nature of Service</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Numerology readings are for entertainment and personal insight purposes</li>
                            <li>Results are not guaranteed and should not be considered professional advice</li>
                            <li>Interpretations are subjective and based on metaphysical principles</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2">7.2 Comprehensive Liability Disclaimer</h3>
                        <p className="font-semibold mb-2">IN NO EVENT SHALL NUMEROLOGYUNLOCKED BE LIABLE FOR ANY:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Direct, indirect, incidental, special, or consequential damages</li>
                            <li>Loss of profits, revenue, or business</li>
                            <li>Loss of data or information</li>
                        </ul>
                    </div>
                </section>

                {/* Contact Information */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-[#1A243B] mb-3">12. Contact Information</h2>
                    <div className="bg-gray-50 p-6 rounded-lg">
                        <p>For questions about these terms:</p>
                        <ul className="list-none">
                            <li><strong>Email:</strong> numerologyunlockedofc@gmail.com</li>
                            <li><strong>Website:</strong> http://www.numerology-unlocked.com/contact</li>
                        </ul>
                    </div>
                </section>

                {/* Final Notice */}
                <section className="mt-8 p-4 bg-gray-50 rounded-lg">
                    <p className="font-semibold">By using NumerologyUnlocked, you acknowledge that you have read, understood, and agree to these Terms of Use.</p>
                </section>
            </div>
        </div>
    );
}

export default TermsOfUse; 
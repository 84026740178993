import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useBlog } from '../contexts/BlogContext';

function BlogPost() {
    const { id } = useParams();
    const { posts } = useBlog();
    const post = posts[id];

    if (!post) {
        return (
            <div className="max-w-3xl mx-auto px-4 py-16">
                <h1 className="text-2xl font-bold text-gray-900">Post not found</h1>
                <Link to="/blog" className="text-indigo-600 hover:text-indigo-800">
                    Return to blog posts
                </Link>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-screen mt-[4rem]">
            {/* Back to Blog Posts Link */}
            <div className="max-w-3xl mx-auto px-4 py-4">
                <Link
                    to="/blog"
                    className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center"
                >
                    ← Back to blog posts
                </Link>
            </div>

            {/* Hero Section */}
            <div className="relative">
                <div className="w-full h-96">
                    <img
                        className="w-full h-full object-cover"
                        src={post.imageUrl}
                        alt={post.title}
                    />
                    <div className="absolute inset-0 bg-black opacity-40"></div>
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-center text-white px-4">
                        <h1 className="text-4xl font-bold tracking-tight sm:text-5xl w-[90%] mx-auto">
                            {post.title}
                        </h1>
                        <p className="mt-4 text-xl">{post.description}</p>
                    </div>
                </div>
            </div>

            {/* Article Content */}
            <div className="max-w-3xl mx-auto px-4 py-12">
                {/* Author Info */}
                <div className="flex items-center justify-between mb-8 pb-8 border-b border-gray-200">
                    <div className="flex items-center space-x-4">
                        <img
                            className="w-12 h-12 rounded-full"
                            src={post.author.avatar}
                            alt={post.author.name}
                        />
                        <div>
                            <div className="text-lg font-medium text-gray-900">
                                {post.author.name}
                            </div>
                            <div className="text-gray-500">{post.author.role}</div>
                        </div>
                    </div>
                    <div className="text-sm text-gray-500">
                        <time dateTime={post.date}>{post.date}</time>
                        <span className="mx-1">·</span>
                        <span>{post.readTime}</span>
                    </div>
                </div>

                {/* Article Body */}
                <article className="prose prose-lg max-w-none">
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </article>

                {/* Share and Navigation */}
                <div className="mt-12 pt-8 border-t border-gray-200">
                    <div className="flex items-center justify-between">
                        <Link
                            to="/blog"
                            className="text-indigo-600 hover:text-indigo-800 font-medium"
                        >
                            ← Back to all posts
                        </Link>
                        <div className="flex space-x-4">
                            <button className="text-gray-500 hover:text-gray-700">
                                <span className="sr-only">Share on Twitter</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                            </button>
                            <button className="text-gray-500 hover:text-gray-700">
                                <span className="sr-only">Share on LinkedIn</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPost; 
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Modal from './Modal';
import ConfigService from '../services/ConfigService';

const stripePromise = loadStripe(ConfigService.getStripePublishableKey());

const TokenPackage = ({ pkg, isSelected, onClick, onCloseModal }) => (
    <div
        onClick={onClick}
        className={`
            relative p-6 rounded-lg cursor-pointer transition-all
            ${isSelected ? 'bg-indigo-50 border-2 border-indigo-500' : 'bg-white border border-gray-200'}
            ${pkg.popular ? 'transform hover:scale-105' : 'hover:bg-gray-50'}
        `}
    >
        {pkg.popular && (
            <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-indigo-500 text-white px-3 py-1 rounded-full text-sm w-[120px] text-center">
                Most Popular
            </span>
        )}
        <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">{pkg.label}</h3>
            <p className="text-3xl font-bold text-indigo-600 mb-2">
                ${(pkg.amount / 100).toFixed(2)}
            </p>
            <p className="text-gray-500">{pkg.tokens.toLocaleString()} tokens</p>
        </div>
    </div>
);

const CheckoutForm = ({ selectedPackage, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setLoading(true);
        try {
            const response = await fetch('http://localhost:8000/api/create-payment-intent', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount: selectedPackage.amount }),
                credentials: 'include',
            });

            const { clientSecret } = await response.json();

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (error) {
                onError(error.message);
            } else if (paymentIntent.status === 'succeeded') {
                onSuccess(paymentIntent);
            }
        } catch (err) {
            onError('Payment failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-6">
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>
            <button
                disabled={loading}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full"
            >
                {loading ? 'Processing...' : 'Buy Now'}
            </button>
        </form>
    );
};

const TokenPurchaseModal = ({ isOpen, onClose, onSuccess }) => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [error, setError] = useState(null);

    const tokenPackages = [
        {
            tokens: 100,
            amount: 1000, // $10.00
            label: 'Starter Pack',
            popular: false
        },
        {
            tokens: 500,
            amount: 4500, // $45.00
            label: 'Value Pack',
            popular: true
        },
        {
            tokens: 1000,
            amount: 8000, // $80.00
            label: 'Pro Pack',
            popular: false
        },
        {
            tokens: 5000,
            amount: 35000, // $350.00
            label: 'Ultimate Pack',
            popular: false
        }
    ];

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="p-8 max-w-4xl mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-center">Token Balance</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {tokenPackages.map((pkg) => (
                        <TokenPackage
                            key={pkg.tokens}
                            pkg={pkg}
                            isSelected={selectedPackage?.tokens === pkg.tokens}
                            onClick={() => setSelectedPackage(pkg)}
                            onCloseModal={onClose}
                        />
                    ))}
                </div>

                {selectedPackage && (
                    <div className="mt-8 max-w-md mx-auto">
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                selectedPackage={selectedPackage}
                                onSuccess={async (paymentIntent) => {
                                    onSuccess(paymentIntent);
                                    onClose();
                                }}
                                onError={setError}
                            />
                        </Elements>
                    </div>
                )}

                {error && (
                    <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-md">
                        {error}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default TokenPurchaseModal;
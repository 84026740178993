import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBlog } from '../contexts/BlogContext';

function BlogPosts() {
    const { posts } = useBlog();
    const navigate = useNavigate();
    const postsArray = Object.values(posts);

    const handlePostClick = (postId) => {
        // Scroll to top before navigation
        window.scrollTo(0, 0);
        navigate(`/blog/${postId}`);
    };

    return (
        <div className="bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" style={{ marginTop: '4rem' }}>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Latest Insights
                    </h2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        Explore the latest articles about numerology and spiritual growth
                    </p>
                </div>

                <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {postsArray.map((post) => (
                        <div 
                            key={post.id}
                            onClick={() => handlePostClick(post.id)}
                            className="flex flex-col rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:transform hover:scale-105 cursor-pointer"
                        >
                            <div className="flex-shrink-0">
                                <img
                                    className="h-48 w-full object-cover"
                                    src={post.imageUrl}
                                    alt={post.title}
                                />
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-indigo-600">
                                        {post.category}
                                    </p>
                                    <div className="block mt-2">
                                        <p className="text-xl font-semibold text-gray-900">
                                            {post.title}
                                        </p>
                                        <p className="mt-3 text-base text-gray-500">
                                            {post.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex text-sm text-gray-500">
                                        <time dateTime={post.date}>{post.date}</time>
                                        <span className="mx-1">·</span>
                                        <span>{post.readTime}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BlogPosts; 
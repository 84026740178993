import React, { useState } from 'react';

const faqs = [
    {
        question: "What is numerology?",
        answer: "Numerology is an ancient practice that reveals the mystical relationship between numbers and life events, helping you understand your path and potential."
    },
    {
        question: "How can numerology help me?",
        answer: "Numerology provides insights into your personality, life path, relationships, and career choices, helping you make more informed decisions about your future."
    },
    {
        question: "How do I get started?",
        answer: "Begin with a free basic numerology reading to discover your life path number, then explore our comprehensive personal analysis for deeper insights."
    }
];

function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="max-w-3xl mx-auto px-4">
            <h2 className="text-3xl font-extrabold text-[#1A243B] mb-8 text-center">
                Frequently Asked Questions
            </h2>
            <div className="space-y-6">
                {faqs.map((faq, index) => (
                    <div 
                        key={index}
                        className="border-b border-[#3A3A8C]/10 last:border-b-0"
                    >
                        <button
                            className="w-full text-left py-5 focus:outline-none group"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="flex items-center justify-between">
                                <h3 className="text-lg font-medium text-[#1A243B]">
                                    {faq.question}
                                </h3>
                                <span>
                                    {openIndex === index ? (
                                        <svg 
                                            className="w-5 h-5 text-[#3A3A8C]" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="currentColor"
                                        >
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth={1.5} 
                                                d="M20 12H4" 
                                            />
                                        </svg>
                                    ) : (
                                        <svg 
                                            className="w-5 h-5 text-[#3A3A8C]" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="currentColor"
                                        >
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth={1.5} 
                                                d="M12 4v16m8-8H4" 
                                            />
                                        </svg>
                                    )}
                                </span>
                            </div>
                        </button>
                        {openIndex === index && (
                            <div className="pb-5">
                                <p className="text-gray-600 leading-relaxed">
                                    {faq.answer}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ; 
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useBlog } from '../contexts/BlogContext';

function Footer() {
    const { posts } = useBlog();

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="w-full bg-gray-800 text-white py-6">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div className="text-left">
                        <h3 className="text-lg font-semibold mb-3">Numerology Insights</h3>
                        <p className="text-gray-300 text-sm">
                            Discover the hidden meanings in your numbers and transform your life
                            through the ancient wisdom of numerology.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div className="text-left">
                        <h3 className="text-lg font-semibold mb-3">Quick Links</h3>
                        <ul className="space-y-2">
                            <li><Link to="/" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Home</Link></li>
                            <li><Link to="/analysis" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Get Analysis</Link></li>
                            <li><Link to="/faq" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>FAQ</Link></li>
                            <li><Link to="/blog" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Blog</Link></li>
                        </ul>
                    </div>

                    {/* Legal */}
                    <div className="text-left">
                        <h3 className="text-lg font-semibold mb-3">Legal</h3>
                        <ul className="space-y-2">
                            <li><Link to="/privacy-policy" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Privacy Policy</Link></li>
                            <li><Link to="/terms" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Terms of Use</Link></li>
                            <li><Link to="/contact" className="text-gray-300 hover:text-white text-sm" onClick={handleLinkClick}>Contact Us</Link></li>
                        </ul>
                    </div>

                    {/* Recent Blog Posts */}
                    <div className="text-left">
                        <h3 className="text-lg font-semibold mb-3">Recent Posts</h3>
                        <ul className="space-y-2">
                            {Object.values(posts).slice(0, 3).map((post) => (
                                <li key={post.id}>
                                    <Link 
                                        to={`/blog/${post.slug}`} 
                                        className="text-gray-300 hover:text-white text-sm"
                                        onClick={handleLinkClick}
                                    >
                                        {post.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="border-t border-gray-700 mt-6 pt-6 text-center">
                    <p className="text-sm text-gray-300">© {new Date().getFullYear()} Numerology Insights. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer; 